import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { ImageUpload } from "../../../components";
import BackgroundList from "./BackgroundList";

export default function Background({
  backgroundImage,
  setBackgroundImage,
  selectedBg,
  setSelectedBg,
}) {
  return (
    <div className="wrap-background">
      <div>
        <p className="lable">Choose background from list</p>
        <BackgroundList
          setBackgroundImage={setBackgroundImage}
          selectedBg={selectedBg}
          setSelectedBg={setSelectedBg}
        />
      </div>
      <div>
        <p className="lable">Upload background</p>
        <ImageUpload
          setFileUrl={setBackgroundImage}
          fileUrl={backgroundImage}
          width="550px"
          height="300px"
        />
      </div>
    </div>
  );
}
