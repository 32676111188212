import React, { useState } from "react";
import { SketchPicker } from "react-color";
import "../styles/colors-picker.scss";

export default function ColorsPicker({ color, onChange }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker((prev) => !prev);
  };
  return (
    <div>
      <div className="swatch" onClick={handleClick}>
        <div className="color" style={{ backgroundColor: color }} />
      </div>
      {displayColorPicker ? (
        <div className="popover">
          <div className="cover" onClick={() => setDisplayColorPicker(false)} />
          <SketchPicker color={color} onChange={onChange} />
        </div>
      ) : null}
    </div>
  );
}
