import { InputNumber } from "antd";
import { ImageUpload } from "../../../components";

export default function Logo({ logo, setLogo }) {
  return (
    <div className="wrap-logo">
      <div style={{ width: "104px", height: "104px" }}>
        <p className="lable">Upload logo</p>
        <ImageUpload
          setFileUrl={setLogo}
          fileUrl={logo}
          width="104px"
          height="104px"
        />
      </div>
      <div>
        <p className="lable">Resize logo</p>
        <InputNumber
          value={logo?.size}
          onChange={(val) => setLogo({ ...logo, size: val })}
        />
      </div>
    </div>
  );
}
