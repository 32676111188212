import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBrandingLogo } from "../../store/features/profileSlice";

import { Tag, Space, Tabs, message } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import {
  PageComponent,
  InfoPopover,
  ButtonComponent,
  confirmModal,
} from "../../components";

import { infoModal } from "./components/InfoModal";
import GeneralInfo from "./components/GeneralInfo";
import AppMenu from "./components/app-menu/AppMenu";
import LiveTv from "./components/LiveTv";
import Media from "./components/Media";
import PlayerPage from "./components/player/PlayerPage";

import "./styles/branding.scss";

export default function AppBrandingPage() {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { profile } = useSelector((store) => store.profile);

  const defaultBg = useRef(null);
  const [selectedBg, setSelectedBg] = useState(null);

  const [isLogoChange, setIsLogoChange] = useState(false);

  const [provider, setProvider] = useState({
    id: null,
    provider_id: null,
  });

  const [logo, setLogo] = useState({
    file: null,
    url: "",
    size: 20,
  });

  const [backgroundImage, setBackgroundImage] = useState({
    file: null,
    url: "",
  });

  const [colors, setColors] = useState({
    primary_color: "",
    secondary_color: "",
  });

  const [menu, setMenu] = useState({
    menu_option: "classic",
  });

  const [playerIconsCollection, setPlayerIconsCollection] = useState(1);

  const [playerVersion, setPlayerVersion] = useState(1);
  const [playerItemsColor, setPlayerItemsColor] = useState("");

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("General Info");

  const getAppBranding = () => {
    REQUESTS.APP_BRANDINGS.GET()
      .then((data) => {
        if (data) {
          setLogo({
            ...logo,
            url: data.logo,
            size: data.logo_size,
          });
          setBackgroundImage({
            ...backgroundImage,
            url: data.background_image,
          });
          setMenu({
            ...menu,
            menu_option: data.menu_option,
          });
          setColors({
            ...colors,
            primary_color: data.primary_color,
            secondary_color: data.secondary_color,
          });
          setProvider({
            id: data.id,
            provider_id: data.provider_id,
          });
          setPlayerIconsCollection(data.player_icons_collection);
          setPlayerVersion(data.player_version);
          setPlayerItemsColor(data.player_items_color);
        }
      })
      .catch((err) => {});
  };

  async function getStaticImageUrl() {
    if (selectedBg == backgroundImage.url) {
      const url = backgroundImage.url;

      const segments = url?.split("/");
      const filename = segments?.pop();

      // Fetch the image as a Blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Convert Blob to File object
      const file = new File([blob], filename, {
        type: blob.type,
      });

      // Return the file object with the originFileObj property
      return { originFileObj: file };
    } else {
      return;
    }
  }

  useEffect(() => {
    getAppBranding();
  }, []);

  useEffect(() => {
    if (selectedBg) {
      setBackgroundImage({ ...backgroundImage, url: selectedBg });
    }
    if (selectedBg == backgroundImage.url) {
      getStaticImageUrl().then((result) => {
        defaultBg.current = result.originFileObj;
      });
    }
  }, [selectedBg, backgroundImage.url]);

  const items = [
    {
      key: "General Info",
      label: "General Info",
      children: (
        <GeneralInfo
          logo={logo}
          setLogo={setLogo}
          backgroundImage={backgroundImage}
          setBackgroundImage={setBackgroundImage}
          colors={colors}
          setColors={setColors}
          selectedBg={selectedBg}
          setSelectedBg={setSelectedBg}
        />
      ),
    },
    {
      key: "Menu",
      label: "Menu",
      children: (
        <AppMenu
          colors={colors}
          logo={logo}
          backgroundImage={backgroundImage}
          menu={menu}
          setMenu={setMenu}
        />
      ),
    },
    {
      key: "Live TV",
      label: "Live TV",
      children: <LiveTv backgroundImage={backgroundImage} colors={colors} />,
    },
    {
      key: "Media",
      label: "Media",
      children: <Media backgroundImage={backgroundImage} colors={colors} />,
    },
    {
      key: "Player",
      label: "Player",
      children: (
        <PlayerPage
          playerIconsCollection={playerIconsCollection}
          setPlayerIconsCollection={setPlayerIconsCollection}
          playerVersion={playerVersion}
          setPlayerVersion={setPlayerVersion}
          playerItemsColor={playerItemsColor}
          setPlayerItemsColor={setPlayerItemsColor}
        />
      ),
    },
  ];

  const onSubmit = () => {
    setLoading(true);

    const formData = new FormData();

    if (logo?.file) {
      formData.append("logo", logo?.file);
    }

    if (logo?.size && logo?.size > 0) {
      formData.append("logo_size", logo?.size);
    }

    if (backgroundImage?.file) {
      formData.append("background_image", backgroundImage?.file);
    }

    if (selectedBg == backgroundImage.url && !backgroundImage?.file) {
      formData.append("background_image", defaultBg.current);
    }

    formData.append("primary_color", colors?.primary_color);
    formData.append("secondary_color", colors?.secondary_color);
    formData.append("menu_option", menu?.menu_option);

    formData.append("player_icons_collection", playerIconsCollection);
    formData.append("player_items_color", playerItemsColor);
    formData.append("player_version", playerVersion);

    REQUESTS.APP_BRANDINGS.PUT(formData)
      .then((res) => {
        setLoading(false);
        message.success(res);
        setIsLogoChange(!isLogoChange);
        getAppBranding();
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const onReset = () => {
    confirmModal({
      title: translate("Do you want to reset"),
      okText: translate("Yes"),
      cancelText: translate("Cancel"),
      action: () => {
        REQUESTS.BRAND_RESET()
          .then((res) => {
            message.success(res);
            getAppBranding();
          })
          .catch((err) => {
            message.error(err);
          });
      },
    });
  };

  useEffect(() => {
    dispatch(setBrandingLogo(isLogoChange));
  }, [isLogoChange]);

  return (
    <PageComponent>
      <div className="head-page">
        <h3>
          {translate("Branding")}
          <InfoPopover content={infoModal()} title={null} />
        </h3>

        {profile?.branding ? (
          <Space direction="horizontal">
            <ButtonComponent
              disabled={!profile?.branding ? true : false}
              onClick={onReset}
              style={{
                backgroundColor: "#ff7875",
              }}
            >
              {translate("Reset")}
            </ButtonComponent>
            <ButtonComponent
              type="primary"
              onClick={onSubmit}
              loading={loading}
              disabled={!profile?.branding ? true : false}
            >
              {translate("Save")}
            </ButtonComponent>
          </Space>
        ) : (
          <Tag color="gold">{translate("You cannot use branding")}</Tag>
        )}
      </div>

      <Tabs
        activeKey={activeTab}
        items={items}
        onChange={(key) => setActiveTab(key)}
      />
    </PageComponent>
  );
}
