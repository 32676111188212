import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import useTranslate from "../../../../hooks/translator";

import MenuRms from "./MenuRms";
import MenuClassic from "./MenuClassic";

export default function AppMenu({
  colors,
  logo,
  backgroundImage,
  menu,
  setMenu,
}) {
  const translate = useTranslate();
  const [segmented, setSegmented] = useState("");

  const onChangeVersion = (e) => {
    setSegmented(e.target.value);
    setMenu({ ...menu, menu_option: e.target.value });
  };

  useEffect(() => {
    if (!segmented) {
      setSegmented(menu.menu_option);
    }
  }, [menu.menu_option, segmented]);

  return (
    <>
      <Radio.Group onChange={onChangeVersion} value={segmented}>
        <Radio value="rms">{translate("Version")} 1</Radio>
        <Radio value="classic">{translate("Version")} 2</Radio>
      </Radio.Group>

      <div className="mtop-20">
        {segmented == "rms" ? (
          <MenuRms
            colors={colors}
            logo={logo}
            backgroundImage={backgroundImage}
          />
        ) : (
          <MenuClassic
            colors={colors}
            logo={logo}
            backgroundImage={backgroundImage}
          />
        )}
      </div>
    </>
  );
}
