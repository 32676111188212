import { tv_classic_menu_buttons } from "../../../../config/data";

export default function MenuRmsButtons({ primaryColor }) {
  const returnColor = (color, percent) => {
    if (color.startsWith("linear-gradient")) {
      let rgbaPattern = /rgba\(\d{1,3},\d{1,3},\d{1,3},\d*\.?\d+\)/;
      let match = color.match(rgbaPattern);
      color = match ? match[0] : "";
      return color.replace(/rgba\(\d+,\d+,\d+,\d+\)/, (match) => {
        return match.replace(/\d+\)$/, `${percent})`);
      });
    } else if (color.startsWith("#")) {
      color = color.replace(/^#/, "");
      // Parse the r, g, b values
      let r = parseInt(color.substring(0, 2), 16);
      let g = parseInt(color.substring(2, 4), 16);
      let b = parseInt(color.substring(4, 6), 16);

      // Calculate the new values, ensuring they don't go below 0
      r = Math.max(0, Math.min(255, r - Math.round((r * percent) / 100)));
      g = Math.max(0, Math.min(255, g - Math.round((g * percent) / 100)));
      b = Math.max(0, Math.min(255, b - Math.round((b * percent) / 100)));

      // Return the darkened color in hex format
      return (
        "#" +
        r.toString(16).padStart(2, "0") +
        g.toString(16).padStart(2, "0") +
        b.toString(16).padStart(2, "0")
      );
    } else if (color.startsWith("rgb")) {
      return color.replace(/rgba\(\d+,\d+,\d+,\d+\)/, (match) => {
        return match.replace(/\d+\)$/, `${percent})`);
      });
    } else {
      return color;
    }
  };

  return (
    <div className="menu-buttons">
      {tv_classic_menu_buttons.map((el, i) => (
        <div className="menu-buttons-list" key={el.id}>
          <img
            src={el.src}
            alt={el.title}
            className={el.id == 1 ? "active-btn" : ""}
            style={{
              background:
                el.id == 1 ? returnColor(primaryColor, 10) : "transparent",
              boxShadow:
                el.id == 1
                  ? `0.49px 0.49px 20.97px 0px ${primaryColor}`
                  : "none",
            }}
          />
          <p>{el.title}</p>
        </div>
      ))}
    </div>
  );
}
