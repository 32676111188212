import React, { useState } from "react";
import { Radio } from "antd";

import useTranslate from "../../../../hooks/translator";
import ColorsPicker from "../../../../components/ColorsPicker";

import PlayerFirst from "./PlayerFirst";
import PlayerSecond from "./PlayerSecond";

import "../../styles/player.scss";

export default function PlayerPage({
  playerIconsCollection,
  setPlayerIconsCollection,
  playerVersion,
  setPlayerVersion,
  playerItemsColor,
  setPlayerItemsColor,
}) {
  const collection = [
    {
      id: 1,
      name: "Collection 1",
      back: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
        >
          <path
            d="M44 23.375H5.37109L22.2793 40.2832L20.3457 42.2168L0.128906 22L20.3457 1.7832L22.2793 3.7168L5.37109 20.625H44V23.375Z"
            fill="currentColor"
          />
        </svg>
      ),
      play: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="currentColor"
        >
          <path d="M320-200v-560l440 280-440 280Z" />
        </svg>
      ),

      pause: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32px"
          viewBox="0 -960 960 960"
          width="32px"
          fill="currentColor"
        >
          <path d="M560-200v-560h160v560H560Zm-320 0v-560h160v560H240Z" />
        </svg>
      ),
      backward: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="35"
          viewBox="0 0 32 35"
          fill="none"
        >
          <path
            d="M13.4332 23.5067V15.105L11.0327 16.6803"
            stroke="currentColor"
            strokeWidth="1.80036"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.0342 22.9818C17.4103 23.3114 17.8778 23.5067 18.3845 23.5067C19.6273 23.5067 20.6349 22.3312 20.6349 20.8811C20.6349 19.4311 19.6273 18.2556 18.3845 18.2556C17.8778 18.2556 17.4103 18.4509 17.0342 18.7805L17.4843 15.105H20.6349"
            stroke="currentColor"
            strokeWidth="1.80036"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.01117 8.86869C0.193633 14.1421 -0.124629 23.0014 5.30031 28.6564C10.7253 34.3114 19.8391 34.6208 25.6566 29.3474C31.4742 24.074 31.7924 15.2147 26.3675 9.5597C23.2818 6.3431 19.0026 4.85601 14.8153 5.14208M16.8627 1.90234L13.1943 5.30256L16.8627 8.94895"
            stroke="currentColor"
            strokeWidth="2.40049"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      forward: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="35"
          viewBox="0 0 32 35"
          fill="none"
        >
          <path
            d="M13.6471 23.5067V15.105L11.2466 16.6803"
            stroke="currentColor"
            strokeWidth="1.80036"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.248 22.9818C17.6242 23.3114 18.0917 23.5067 18.5983 23.5067C19.8412 23.5067 20.8488 22.3312 20.8488 20.8811C20.8488 19.4311 19.8412 18.2556 18.5983 18.2556C18.0917 18.2556 17.6242 18.4509 17.248 18.7805L17.6981 15.105H20.8488"
            stroke="currentColor"
            strokeWidth="1.80036"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.8702 8.86869C31.6877 14.1421 32.006 23.0014 26.581 28.6564C21.1561 34.3114 12.0423 34.6208 6.22472 29.3474C0.40718 24.074 0.0889193 15.2147 5.51386 9.5597C8.59958 6.3431 12.8788 4.85601 17.066 5.14208M15.0187 1.90234L18.6871 5.30256L15.0187 8.94895"
            stroke="currentColor"
            strokeWidth="2.40049"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),

      subtitles: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <path
            d="M8.16667 15.1667H12.8333M17.5 15.1667H19.8333M8.16667 10.5H10.5M15.1667 10.5H19.8333M24.5 17.5C24.5 18.1188 24.2542 18.7123 23.8166 19.1499C23.379 19.5875 22.7855 19.8333 22.1667 19.8333H8.16667L3.5 24.5V5.83333C3.5 5.21449 3.74583 4.621 4.18342 4.18342C4.621 3.74583 5.21449 3.5 5.83333 3.5H22.1667C22.7855 3.5 23.379 3.74583 23.8166 4.18342C24.2542 4.621 24.5 5.21449 24.5 5.83333V17.5Z"
            stroke="currentColor"
            strokeWidth="2.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      skip: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="28px"
          viewBox="0 -960 960 960"
          width="28px"
          fill="currentColor"
        >
          <path d="M660-240v-480h80v480h-80Zm-440 0v-480l360 240-360 240Zm80-240Zm0 90 136-90-136-90v180Z" />
        </svg>
      ),
    },
    {
      id: 2,
      name: "Collection 2",
      back: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
        >
          <path
            d="M44 23.375H5.37109L22.2793 40.2832L20.3457 42.2168L0.128906 22L20.3457 1.7832L22.2793 3.7168L5.37109 20.625H44V23.375Z"
            fill="currentColor"
          />
        </svg>
      ),
      play: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="83"
          height="83"
          viewBox="0 0 83 83"
          fill="none"
        >
          <g clip-path="url(#clip0_2_6)">
            <path
              d="M82.7031 41.8691C82.7031 52.7218 78.3919 63.1299 70.718 70.8039C63.044 78.4779 52.6358 82.7891 41.7832 82.7891C30.9306 82.7891 20.5224 78.4779 12.8484 70.8039C5.17448 63.1299 0.863281 52.7218 0.863281 41.8691C0.863281 31.0165 5.17448 20.6084 12.8484 12.9344C20.5224 5.26041 30.9306 0.949219 41.7832 0.949219C52.6358 0.949219 63.044 5.26041 70.718 12.9344C78.3919 20.6084 82.7031 31.0165 82.7031 41.8691ZM35.5941 26.9999C35.2117 26.7276 34.7617 26.5658 34.2934 26.5323C33.8252 26.4987 33.3567 26.5947 32.9394 26.8097C32.5221 27.0247 32.172 27.3504 31.9275 27.7512C31.683 28.1519 31.5535 28.6122 31.5532 29.0817V54.6566C31.5535 55.1261 31.683 55.5864 31.9275 55.9871C32.172 56.3878 32.5221 56.7136 32.9394 56.9286C33.3567 57.1435 33.8252 57.2395 34.2934 57.206C34.7617 57.1725 35.2117 57.0107 35.5941 56.7384L53.4965 43.9509C53.8281 43.7144 54.0983 43.402 54.2847 43.0399C54.4712 42.6778 54.5685 42.2764 54.5685 41.8691C54.5685 41.4619 54.4712 41.0605 54.2847 40.6984C54.0983 40.3363 53.8281 40.0239 53.4965 39.7873L35.5941 26.9999Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_2_6">
              <rect
                width="81.8398"
                height="81.8398"
                fill="currentColor"
                transform="translate(0.863281 0.949219)"
              />
            </clipPath>
          </defs>
        </svg>
      ),

      pause: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="82"
          height="82"
          viewBox="0 0 82 82"
          fill="none"
        >
          <g clip-path="url(#clip0_45_349)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M69.8547 69.8547C77.5286 62.1807 81.8398 51.7726 81.8398 40.9199C81.8398 30.0673 77.5286 19.6591 69.8547 11.9852C62.1807 4.31119 51.7726 0 40.9199 0C30.0673 0 19.6591 4.31119 11.9852 11.9852C4.31119 19.6591 0 30.0673 0 40.9199C0 51.7726 4.31119 62.1807 11.9852 69.8547C19.6591 77.5286 30.0673 81.8398 40.9199 81.8398C51.7726 81.8398 62.1807 77.5286 69.8547 69.8547ZM36.8034 56.3333H28.1367V26H36.8034V56.3333ZM45.4701 56.3333V26H54.1367V56.3333H45.4701Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_45_349">
              <rect width="81.8398" height="81.8398" fill="currentColor" />
            </clipPath>
          </defs>
        </svg>
      ),

      backward: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
        >
          <path
            opacity="0.9"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.709 59.418C13.3012 59.418 0 46.1168 0 29.709C0 13.3012 13.3012 0 29.709 0C46.1168 0 59.418 13.3012 59.418 29.709C59.418 46.1168 46.1168 59.418 29.709 59.418ZM29.7419 19.9782C29.9407 20.3016 30.0459 20.6737 30.0458 21.0533V27.5413L41.1493 19.3984C41.4552 19.1737 41.8175 19.0382 42.1957 19.007C42.574 18.9757 42.9536 19.0499 43.2922 19.2214C43.6308 19.3928 43.9153 19.6548 44.1141 19.9782C44.3128 20.3016 44.418 20.6737 44.418 21.0533V39.5872C44.418 39.9668 44.3128 40.3389 44.1141 40.6623C43.9153 40.9857 43.6308 41.2476 43.2922 41.4191C42.9536 41.5905 42.574 41.6648 42.1957 41.6335C41.8175 41.6022 41.4552 41.4667 41.1493 41.242L30.0458 33.0992V39.5872C30.0459 39.9668 29.9407 40.3389 29.7419 40.6623C29.5432 40.9857 29.2587 41.2476 28.9201 41.4191C28.5814 41.5905 28.2019 41.6648 27.8236 41.6335C27.4453 41.6022 27.0831 41.4667 26.7772 41.242L14.7046 32.3909C14.3792 32.1525 14.1145 31.8408 13.932 31.4811C13.7496 31.1213 13.6545 30.7236 13.6545 30.3202C13.6545 29.9169 13.7496 29.5192 13.932 29.1594C14.1145 28.7996 14.3792 28.4879 14.7046 28.2496L26.7772 19.3984C27.0831 19.1737 27.4453 19.0382 27.8236 19.007C28.2019 18.9757 28.5814 19.0499 28.9201 19.2214C29.2587 19.3928 29.5432 19.6548 29.7419 19.9782Z"
            fill="currentColor"
          />
        </svg>
      ),

      forward: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
        >
          <path
            opacity="0.9"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.709 59.418C46.1168 59.418 59.418 46.1168 59.418 29.709C59.418 13.3012 46.1168 0 29.709 0C13.3012 0 0 13.3012 0 29.709C0 46.1168 13.3012 59.418 29.709 59.418ZM29.676 19.9782C29.4773 20.3016 29.3721 20.6737 29.3721 21.0533V27.5413L18.2686 19.3984C17.9627 19.1737 17.6005 19.0382 17.2222 19.007C16.844 18.9757 16.4644 19.0499 16.1258 19.2214C15.7871 19.3928 15.5026 19.6548 15.3039 19.9782C15.1052 20.3016 15 20.6737 15 21.0533V39.5872C15 39.9668 15.1052 40.3389 15.3039 40.6623C15.5026 40.9857 15.7871 41.2476 16.1258 41.4191C16.4644 41.5905 16.844 41.6648 17.2222 41.6335C17.6005 41.6022 17.9627 41.4667 18.2686 41.242L29.3721 33.0992V39.5872C29.3721 39.9668 29.4773 40.3389 29.676 40.6623C29.8748 40.9857 30.1593 41.2476 30.4979 41.4191C30.8365 41.5905 31.2161 41.6648 31.5944 41.6335C31.9726 41.6022 32.3349 41.4667 32.6408 41.242L44.7134 32.3909C45.0388 32.1525 45.3035 31.8408 45.486 31.4811C45.6684 31.1213 45.7635 30.7236 45.7635 30.3202C45.7635 29.9169 45.6684 29.5192 45.486 29.1594C45.3035 28.7996 45.0388 28.4879 44.7134 28.2496L32.6408 19.3984C32.3349 19.1737 31.9726 19.0382 31.5944 19.007C31.2161 18.9757 30.8365 19.0499 30.4979 19.2214C30.1593 19.3928 29.8748 19.6548 29.676 19.9782Z"
            fill="currentColor"
          />
        </svg>
      ),

      subtitles: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
        >
          <path
            opacity="0.9"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.709 59.418C13.3012 59.418 0 46.1168 0 29.709C0 13.3012 13.3012 0 29.709 0C46.1168 0 59.418 13.3012 59.418 29.709C59.418 46.1168 46.1168 59.418 29.709 59.418ZM17.175 17H42.575C44.3212 17 45.75 18.4287 45.75 20.175V39.225C45.75 40.9712 44.3212 42.4 42.575 42.4H17.175C15.4287 42.4 14 40.9712 14 39.225V20.175C14 18.4287 15.4287 17 17.175 17ZM23.525 29.7H17.175V32.875H23.525V29.7ZM17.175 39.225H33.05V36.05H17.175V39.225ZM36.225 39.225H42.575V36.05H36.225V39.225ZM26.7 32.875H42.575V29.7H26.7V32.875Z"
            fill="currentColor"
          />
        </svg>
      ),

      skip: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="61"
          height="60"
          viewBox="0 0 61 60"
          fill="none"
        >
          <path
            opacity="0.9"
            d="M30.5254 0.160156C14.1439 0.160156 0.816406 13.4876 0.816406 29.8691C0.816406 46.2507 14.1439 59.5781 30.5254 59.5781C46.9069 59.5781 60.2344 46.2507 60.2344 29.8691C60.2344 13.4876 46.9069 0.160156 30.5254 0.160156ZM42.409 41.7527H36.4672V29.8691L18.6418 41.7527V17.9855L36.4672 29.8691V17.9855H42.409V41.7527Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
  ];

  const translate = useTranslate();

  const [currentIcons, setCurrentIcons] = useState(collection[0]);

  const onChangeCollection = (e) => {
    switch (e.target.value) {
      case 1:
        setCurrentIcons(collection[0]);
        break;
      case 2:
        setCurrentIcons(collection[1]);
        break;
    }
    setPlayerIconsCollection(e.target.value);
  };

  return (
    <div className="page-player">
      <div className="collections-list">
        <div>
          <p className="panel-title">Choose items color</p>
          <ColorsPicker
            color={playerItemsColor}
            onChange={(color) => setPlayerItemsColor(color.hex)}
          />
        </div>
        <div>
          <p className="panel-title">Choose your icons</p>

          <Radio.Group
            value={playerIconsCollection}
            onChange={onChangeCollection}
            className="radio-group"
          >
            <Radio value={1} className="radio-collections">
              <p>Collection 1</p>

              <div className="collection">
                {collection[0].back}
                {collection[0].pause}
                {collection[0].play}
                {collection[0].backward}
                {collection[0].forward}
                {collection[0].skip}
                {collection[0].subtitles}
              </div>
            </Radio>

            <Radio value={2}>
              <p>Collection 2</p>

              <div className="collection">
                {collection[1].back}
                {collection[1].pause}
                {collection[1].play}
                {collection[1].backward}
                {collection[1].forward}
                {collection[1].skip}
                {collection[1].subtitles}
              </div>
            </Radio>
          </Radio.Group>
        </div>
        <div>
          <p className="panel-title">Choose player</p>
          <Radio.Group
            value={playerVersion}
            className="radio-group"
            onChange={(e) => setPlayerVersion(e.target.value)}
          >
            <Radio value={1} style={{ width: "100%" }}>
              {translate("Player")} 1
            </Radio>
            <Radio value={2} style={{ width: "100%" }}>
              {translate("Player")} 2
            </Radio>
          </Radio.Group>
        </div>
      </div>

      <div>
        {playerVersion == 1 ? (
          <PlayerFirst
            currentIcons={currentIcons}
            playerItemsColor={playerItemsColor}
          />
        ) : (
          <PlayerSecond
            currentIcons={currentIcons}
            playerItemsColor={playerItemsColor}
          />
        )}
      </div>
    </div>
  );
}
