import { top_movies, top_series } from "../../../../config/data";
import MenuRmsButtons from "./MenuRmsButtons";
import ListsMedia from "../ListsMedia";

import "../../styles/menu-rms.scss";

export default function MenuRms({ colors, logo, backgroundImage }) {
  return (
    <div
      className="menu-page-two"
      style={{ backgroundImage: `url(${backgroundImage.url})` }}
    >
      <div className="menu-page-wrap">
        <div className="left-section">
          <div className="logo">
            <img
              src={logo.url}
              alt="logo"
              style={{ width: logo.size ? `${logo.size * 5}px` : "50px" }}
            />
          </div>
          <MenuRmsButtons primaryColor={colors.primary_color} />
        </div>
        <div className="right-section">
          <ListsMedia
            data={top_movies}
            title="10 Recently Added Movies"
            secondaryColor={colors.secondary_color}
            isScale={true}
          />
          <ListsMedia
            data={top_series}
            title="10 Recently Added Series"
            secondaryColor="transparent"
          />
        </div>
      </div>
      <div className="menu-page-two-tv-footer">
        <p>
          <span> Device Key: 3GK689</span>
        </p>
        <p>App version: 1.1.5</p>
      </div>
    </div>
  );
}
