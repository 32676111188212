import {
  BRANDING_TV_MOVIES,
  BRANDING_TV_MOVIES_CATEGORIES,
} from "../../../config/data";
import { ICONS } from "../../../config";
import "../styles/media.scss";
export default function Media({ backgroundImage, colors }) {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage.url})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: " no-repeat",
        position: "relative",
      }}
      className="tv-movies-container"
    >
      <div className="tv-movies-categories_items">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            color: "grey",
            background: "#161719",
            border: `2px solid  "#161719"`,
            height: "40px",
            padding: "5px",
            borderRadius: "4px",
          }}
        >
          <span style={{ color: "#fff", lightingColor: 0 }}>
            {ICONS.SEARCH}
          </span>
          <span>Search</span>
        </div>
        {BRANDING_TV_MOVIES_CATEGORIES.map((item) => (
          <div
            style={{
              background: `${item.id == 5 ? colors.primary_color : "#161719"}`,
              border: `2px solid ${
                item.id == 1 || item.id == 5
                  ? colors.secondary_color
                  : "#161719"
              }`,
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "40px",
              borderRadius: "6px",
              width: "167px",
            }}
            key={item.id}
          >
            <span className="cat-name">{item.id + ".  " + item.name}</span>
            <span style={{ color: "#fff" }} className="lock">
              {item.lock && item.lock}
            </span>
          </div>
        ))}
      </div>

      <div className="tv-movies-container_items">
        {BRANDING_TV_MOVIES.map((item) => (
          <div
            className="tv-movies-container_items_item"
            key={item.id}
            style={{
              border: `2px solid ${
                item.id == 7 ? colors.secondary_color : "#161719"
              }`,
              borderRadius: "6px",
            }}
          >
            <span className="star">
              {item.rating}
              {item.rating}
              {item.rating}
              {item.rating}
              {item.rating}
            </span>
            <span className="move-lock">{item.lock && item.lock}</span>
            <span className="move-favorit">{item.favorit && item.favorit}</span>
            <img src={item.poster} alt="" />
            <div className="block">{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
